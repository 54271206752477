import { Cluster } from './OracleAccounts'

export class NetworkConnection {
  public cluster: Cluster
  public networkName: string
  public networkUrl: string
  public isCustom?: boolean

  constructor(cluster: Cluster, networkName: string, networkUrl: string, isCustom: boolean = false) {
    this.cluster = cluster
    this.networkName = networkName
    this.networkUrl = networkUrl
    this.isCustom = isCustom
  }

  displayName(): string {
    let cluster = 'Testnet'
    if (this.cluster === Cluster.Devnet) {
      cluster = 'Devnet'
    }
    if (this.cluster === Cluster.Mainnet) {
      cluster = 'Mainnet'
    }
    return `${cluster} | ${this.networkName}`
  }
}

const TritonSecretMainnet = process.env.NEXT_PUBLIC_TRITON_MAINNET_SECRET
const TritonSecretDevnet = process.env.NEXT_PUBLIC_TRITON_DEVNET_SECRET
export const NetworkConnections = [
  new NetworkConnection(
    Cluster.Mainnet,
    'Helius',
    'https://amandy-yp5wsa-fast-mainnet.helius-rpc.com'
  ),
  new NetworkConnection(
    Cluster.Mainnet,
    'Solana',
    'https://api.mainnet-beta.solana.com'
  ),
  new NetworkConnection(
    Cluster.Devnet,
    'Triton',
    TritonSecretDevnet || 'https://hedgeso-develope-f69e.devnet.rpcpool.com/'
  ),
  new NetworkConnection(
    Cluster.Devnet,
    'Quicknode',
    'https://summer-proud-night.solana-devnet.quiknode.pro/17c270209a8c3b5de1957ee7909e4e092ae313e4/'
  ),
  new NetworkConnection(Cluster.Devnet, 'Solana', 'https://api.devnet.solana.com'),
  new NetworkConnection(
    Cluster.Devnet,
    'Helius',
    'https://devnet.helius-rpc.com/?api-key=92007c8f-24fc-4e44-86b8-2bb1bcaad107'
  ),
  new NetworkConnection(
    Cluster.Mainnet,
    'Custom',
    '',
    true
  ),
]
