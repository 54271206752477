import { PublicKey, SystemProgram } from '@solana/web3.js'
import { NetworkConnection } from './NetworkConnections'
import { getPythProgramKeyForCluster } from '@pythnetwork/client'

// Define supported price feed IDs
export const PYTH_PRICE_FEEDS = {
  SOL: '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
  mSOL: '0x89875379e70f8fbadc17aef315adf3a8d5d160b811435537e03c97e8aac97d9c', // BSOL/USD used as proxy
  stSOL: '0x55f8289be7450f1ae564dd9798e49e7d797d89adbc54fe4f8c906b1fcb94b0c3', // BNSOL/USD used as proxy
  RAY: '0x91568baa8beb53db23eb3fb7f22c6e8bd303d103919e19733f2bb642d3e7987a',
  SLND: '0xf8d030e4ef460b91ad23eabbbb27aec463e3c30ecc8d5c4b71e92f54a36ccdbd',
  wBTC: '0xc9d8b075a5c69303365ae23633d4e085199bf5c520a3b90fed1322a0342ffc33',
  // Add other collaterals as needed
} as const;

export function getOracleAccount(
  cluster: Cluster,
  oracle: OracleProvider,
  collateral: Collateral
): PublicKey {
  switch (oracle) {
    case OracleProvider.Pyth:
      switch (collateral) {
        case Collateral.SOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('7UVimffxr9ow1uXYxsr4LHAcV58mLzhmwaeKvJ1pjLiE')
            case Cluster.Testnet:
              return new PublicKey('7VJsBtJzgTftYzEeooSDYyjKXvYRWJHdwvbwfBvTg9K')
            case Cluster.Mainnet:
              return new PublicKey('H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG')
          }
        case Collateral.MSOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('9a6RNx3tCu1TSs6TBSfV2XRXEPEZXQ6WB7jRojZRvyeZ')
            case Cluster.Testnet:
              return new PublicKey('FvnczKgxE1WKeqUuP7BisgKxDRZRW2KqiEP14GfVirEo')
            case Cluster.Mainnet:
              return new PublicKey('E4v1BBgoso9s64TQvmyownAVJbhbEPGyzA3qn4n46qj9')
          }
        case Collateral.STSOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('2LwhbcswZekofMNRtDRMukZJNSRUiKYMFbqtBwqjDfke')
            case Cluster.Testnet:
              return new PublicKey('5kLQDJSVWCy11MY2fnJavYKrt8Zf7B4eyz97HLSmWVhw')
            case Cluster.Mainnet:
              return new PublicKey('Bt1hEbY62aMriY1SyQqbeZbm8VmSbQVGBFzSzMuVNWzN')
          }
        case Collateral.WBTC:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('HovQMDrbAgAYPCmHVSrezcSmkMtXSSUsLDFANExrZh2J')
            case Cluster.Mainnet:
              return new PublicKey('GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU')
          }
        case Collateral.SOETH:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('EdVCmQ9FSPcVe5YySXDPCRmc8aDQLKJ9xvYBMZPie1Vw')
            case Cluster.Mainnet:
              return new PublicKey('JBu1AL4obBcCMqKBBxhpWCNUt136ijcuMZLFvTP7iWdB')
          }
        case Collateral.SLND:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('FtwKARNAnZK2Nx1W4KVXzbyDzuRJqmApHRBtQpZ49HDv')
            case Cluster.Testnet:
              return new PublicKey('hSp9Z5nnc6e6eCGGLPQc7bcmosJswJ1ynT3PJFjo9wF')
            case Cluster.Mainnet:
              return new PublicKey('HkGEau5xY1e8REXUFbwvWWvyJGywkgiAZZFpryyraWqJ')
          }
        case Collateral.RAY:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('EhgAdTrgxi4ZoVZLQx1n93vULucPpiFi2BQtz9RJr1y6')
            case Cluster.Testnet:
              return new PublicKey('JDpdv9VibnayNDd2k7JaJW39fdeQT2fT4BmfUpfVj76j')
            case Cluster.Mainnet:
              return new PublicKey('AnLf8tVYCM816gmBjiy8n53eXKKEDydT5piYjjQDPgTB')
          }
        case Collateral.CUSDC:
          return SystemProgram.programId
        case Collateral.CUSDT:
          return SystemProgram.programId
      }
    case OracleProvider.Switchboard:
      switch (collateral) {
        case Collateral.SOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('4JzEBV8kqy6UZGtRW2GAMoyxU2Ei3bfe5bRNkSeYnXtD')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('4JzEBV8kqy6UZGtRW2GAMoyxU2Ei3bfe5bRNkSeYnXtD')
          }
        case Collateral.MSOL:
          return SystemProgram.programId
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('TcVGdU5WzPyNJZFyc1sVecVHuQLNbVLPdpNvpGMXLd5')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('TcVGdU5WzPyNJZFyc1sVecVHuQLNbVLPdpNvpGMXLd5')
          }
        case Collateral.STSOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('9LNYQZLJG5DAyeACCTzBFG6H3sDhehP5xtYLdhrZtQkA')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('9LNYQZLJG5DAyeACCTzBFG6H3sDhehP5xtYLdhrZtQkA')
          }
        case Collateral.WBTC:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('8SXvChNYFhRq4EZuZvnhjrB3jJRQCv4k3P4W6hesH3Ee')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('8SXvChNYFhRq4EZuZvnhjrB3jJRQCv4k3P4W6hesH3Ee')
          }
        case Collateral.RAY:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('Hk4wQt8oXt6rMCaCHTuLPmYVXZ9BdtXj1N8ko6anktFp')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('AS2yMpqPY16tY5hQmpdkomaqSckMuDvR6K9P9tk9FA4d')
          }
        case Collateral.CUSDC:
          return new PublicKey('5jpatqxzG3V6DK6Rf1KCLhnHTAtA6vCdS7h6jk14KxJE')
        case Collateral.CUSDT:
          return new PublicKey('kvbMq5UJ2nH7gDWBUJmf1AcjpqgygoYTDmHjZv8oU2e')
      }
    case OracleProvider.Chainlink:
      switch (collateral) {
        case Collateral.SOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('HgTtcbcmp5BeThax5AU8vg4VwK79qAvAKKFMs8txMLW6')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('CcPVS9bqyXbD9cLnTbhhHazLsrua8QMFUHTutPtjyDzq')
          }
        case Collateral.MSOL:
          return SystemProgram.programId
          switch (cluster) {
            // case Cluster.Devnet:
            //   return new PublicKey('TcVGdU5WzPyNJZFyc1sVecVHuQLNbVLPdpNvpGMXLd5')
            // case Cluster.Testnet:
            //   return
            // case Cluster.Mainnet:
            //   return new PublicKey('TcVGdU5WzPyNJZFyc1sVecVHuQLNbVLPdpNvpGMXLd5')
          }
        case Collateral.STSOL:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('9LNYQZLJG5DAyeACCTzBFG6H3sDhehP5xtYLdhrZtQkA')
            // case Cluster.Testnet:
            //   return
            case Cluster.Mainnet:
              return new PublicKey('9LNYQZLJG5DAyeACCTzBFG6H3sDhehP5xtYLdhrZtQkA')
          }
        case Collateral.WBTC:
          switch (cluster) {
            case Cluster.Devnet:
              return new PublicKey('CzZQBrJCLqjXRfMjRN3fhbxur2QYHUzkpaRwkWsiPqbz')
            case Cluster.Mainnet:
              return new PublicKey('CGmWwBNsTRDENT5gmVZzRu38GnNnMm1K5C3sFiUUyYQX')
          }
        case Collateral.RAY:
          switch (cluster) {
            // case Cluster.Devnet:
              // return new PublicKey('Hk4wQt8oXt6rMCaCHTuLPmYVXZ9BdtXj1N8ko6anktFp')
            // case Cluster.Testnet:
            //   return
            // case Cluster.Mainnet:
              // return new PublicKey('AS2yMpqPY16tY5hQmpdkomaqSckMuDvR6K9P9tk9FA4d')
          }
        case Collateral.CUSDC:
          return SystemProgram.programId
        case Collateral.CUSDT:
          return SystemProgram.programId
      }
  }
  return SystemProgram.programId
}

export function GetUsdcMint(networkConnection: NetworkConnection) {
  if (networkConnection.cluster === Cluster.Mainnet) {
    return new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v')
  }
  if (networkConnection.cluster === Cluster.Devnet) {
    return new PublicKey('CbDyVaG6RgjBABttbYwTF1doucfpDJJPYzjXf3gzruZz')
  }
  return
}

// Helper function to get price feed mapping
export function getPythPriceFeedMapping(symbol: string): string {
  const mapping = {
    'SOL': 'Crypto.SOL/USD',
    'MSOL': 'Crypto.mSOL/USD',
    'WBTC': 'Crypto.BTC/USD',
    'SOETH': 'Crypto.ETH/USD',
    // Add other mappings as needed
  }
  return mapping[symbol] || ''
}

export enum OracleProvider {
  Pyth,
  Switchboard,
  Chainlink,
}

export enum Collateral {
  SOL = 'SOL',
  MSOL = 'mSOL',
  STSOL = 'stSOL',
  WBTC = 'wBTC',
  ORCA = 'ORCA',
  SRM = 'SRM',
  SLND = 'SLND',
  RAY = 'RAY',
  CUSDC = 'cUSDC',
  CUSDT = 'cUSDT',
  SOETH = 'soETH',
}

export enum Cluster {
  Mainnet = 'mainnet-beta',
  Testnet = 'testnet',
  Devnet = 'devnet',
}
