import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import Decimal from 'decimal.js'
import clientVaultTypes from '../../data/vaults'
import { numberWithCommas } from '../../lib/Utils'
import { u128ToDecimal } from '../utils/HedgeConstants'
import { Collateral } from '../utils/OracleAccounts'

export default class VaultType {
  publicKey: PublicKey

  // On Chain Data
  name: string
  collateralMint: PublicKey
  collateralIndex: number
  recentPrice: Decimal
  priceLastUpdatedTimestamp: Decimal
  collateralHeld: Decimal
  denormalizedDebtExtended: Decimal
  debtRedistributionProduct: Decimal
  collateralRedistributionAccumulator: Decimal
  minCollateralRatio: Decimal
  loanInitFee: Decimal
  minDebtPerVault: Decimal
  maxDebtExtended: Decimal
  canBeRedeemed: boolean
  cumulativeRate: Decimal
  cumulativeRateLastUpdated: Decimal
  interestRatePerSecond: Decimal
  firstVaultToRedeem: PublicKey
  emergencyModeThreshold: Decimal
  totalFeesAccumulatedUsh: Decimal
  totalFeesAccumulatedCollateral: Decimal
  deprecated: boolean
  enableLeverage: boolean
  lastRedeemFeeBytes: Decimal
  lastRedeemTimestamp: number

  // Loaded from client
  title: string
  quickDescription: string
  collateral: Collateral
  collateralIcon: string
  lamportsPerCollateralUnit: Decimal
  displayInUI: boolean
  redeemable: boolean
  whiteListOnly: boolean
  maxLeverage: number
  liquidationCollateralRatio: Decimal
  featuredInUI: boolean
  vaultCategory: string
  bannerDescription: string

  constructor(config: any, publicKey: PublicKey) {
    this.publicKey = publicKey
    this.name = config.vaultTypeName
    this.loanInitFee = u128ToDecimal(config.loanInitFee)
    this.minCollateralRatio = u128ToDecimal(config.minCollateralRatio)
    this.liquidationCollateralRatio = u128ToDecimal(config.minCollateralRatio)
    this.interestRatePerSecond = u128ToDecimal(config.interestRatePerSecond)
    this.minDebtPerVault = u128ToDecimal(config.minDebtPerVault)
    this.maxDebtExtended = new Decimal(config.maxDebtExtended.toString())
    this.canBeRedeemed = config.canBeRedeemed
    this.collateralMint = config.collateralMint
    this.firstVaultToRedeem = config.firstVaultToRedeem
    this.totalFeesAccumulatedUsh = new Decimal(config.totalFeesAccumulatedUsh.toString())
    this.totalFeesAccumulatedCollateral = new Decimal(
      config.totalFeesAccumulatedCollateral.toString()
    )
    this.deprecated = config.deprecated
    this.lastRedeemFeeBytes = u128ToDecimal(config.lastRedeemFeeBytes)
    this.lastRedeemTimestamp = config.lastRedeemTimestamp.toNumber()

    this.recentPrice = u128ToDecimal(config.recentPrice?.toString())
    this.priceLastUpdatedTimestamp = new Decimal(config.priceLastUpdatedTimestamp.toString())
    this.collateralHeld = new Decimal(config.collateralHeld.toString())
    this.denormalizedDebtExtended = new Decimal(config.denormalizedDebtExtended.toString())
    this.debtRedistributionProduct = u128ToDecimal(config.debtRedistributionProduct.toString())
    this.collateralRedistributionAccumulator = u128ToDecimal(
      config.collateralRedistributionAccumulator.toString()
    )
    this.cumulativeRate = u128ToDecimal(config.cumulativeRate.toString())
    this.cumulativeRateLastUpdated = new Decimal(config.cumulativeRateLastUpdated.toString())
    this.collateralIndex = config.collateralIndex.toNumber()
    this.emergencyModeThreshold = new Decimal(config.emergencyModeThreshold.toNumber() / 100)

    // These properties are not stored on chain. They're loaded from the website data.
    clientVaultTypes.forEach((clientVault) => {
      if (clientVault.name === this.name) {
        this.title = clientVault.title
        this.quickDescription = clientVault.quickDescription
        this.collateral = clientVault.collateral
        this.collateralIcon = clientVault.collateralIcon
        this.lamportsPerCollateralUnit = clientVault.lamportsPerCollateralUnit
        this.displayInUI = clientVault.displayInUI
        this.redeemable = clientVault.redeemable
        this.whiteListOnly = clientVault.whiteListOnly
        this.enableLeverage = clientVault.enableLeverage
        this.maxLeverage = clientVault.maxLeverage
        this.minCollateralRatio = this.minCollateralRatio.add(new Decimal(clientVault.liqCollateralRatioBuffer.toString()).div(LAMPORTS_PER_SOL))
        this.featuredInUI = clientVault.featuredInUI
        this.vaultCategory = clientVault.vaultCategory
        this.bannerDescription = clientVault.bannerDescription
      }
    })

    // Log vault type details after construction
    // VaultType.logVaultTypeDetails(this)
  }

  static logVaultTypeDetails(vaultType: VaultType) {
    console.log('=== Vault Type Details ===')
    console.log(`Name: ${vaultType.name}`)
    console.log(`Title: ${vaultType.title}`)
    console.log(`Collateral: ${vaultType.collateral}`)
    console.log(`Min Collateral Ratio: ${vaultType.displayMinCollateralRatio()}%`)
    console.log(`Interest Rate: ${vaultType.displayInterestRate()}%`)
    console.log(`Loan Init Fee: ${vaultType.displayLoanInitFee()}`)
    console.log(`Min Debt: ${vaultType.minDebtPerVault.div(LAMPORTS_PER_SOL)} USH`)
    console.log(`Max Debt: ${vaultType.maxDebtExtended.div(LAMPORTS_PER_SOL)} USH`)
    console.log(`Display in UI: ${vaultType.displayInUI}`)
    console.log(`Category: ${vaultType.vaultCategory}`)
    console.log('=======================\n')
  }

  static interestPerSecondToYear(rate: Decimal): Decimal {
    return rate
      .add(1)
      .pow(new Decimal(60 * 60 * 24 * 365))
      .sub(1)
      .mul(100)
      .toDP(2)
  }

  static getInterestRatePerSecond(yearlyRate: Decimal): string {
    // See calculation here: https://www.wolframalpha.com/input?i=%281.06%29%5E%281%2F%28365*24*60*60%29%29
    // const interestPerSecond = new BN('0000000001847694957') // 6%

    const rateString = new Decimal(1)
      .add(yearlyRate)
      .pow(new Decimal(1).div(365 * 24 * 60 * 60))
      .sub(1)
      .mul(new Decimal(10).pow(18))
      .floor()
      .toString()
    return rateString
  }
  /**
   * Get the total debt extended
   *
   * @returns {Decimal} Total debt extended for this collateral type in USH lamports
   */
  getTotalDebtExtended(): Decimal {
    return this.denormalizedDebtExtended.mul(this.cumulativeRate)
  }

  displayLoanInitFee(): string {
    return `${this.loanInitFee.mul(100).toString()}%`
  }

  displayInterestRate(): string {
    const rate = VaultType.interestPerSecondToYear(this.interestRatePerSecond)
    if (rate.isZero()) {
      return '0'
    }
    return VaultType.interestPerSecondToYear(this.interestRatePerSecond).toFixed(2)
  }

  displayName(): string {
    return `${VaultType.interestPerSecondToYear(this.interestRatePerSecond).toFixed(2)}% ${
      this.collateral
    } (${this.displayMinCollateralRatio()}%)`
  }
  displayLongName(): string {
    return `${this.collateral} Collateral | ${VaultType.interestPerSecondToYear(
      this.interestRatePerSecond
    ).toFixed(0)}% Interest | ${this.displayMinCollateralRatio()}% Min Collateral Ratio`
  }

  displayMinCollateralRatio(): string {
    return new Decimal(this.minCollateralRatio.toNumber()).mul(100).toString()
  }

  displayEmergencyThreshold(): string {
    return this.emergencyModeThreshold.mul(100).toString() + '%'
  }

  getCurrentCollateralRatio(price: number): Decimal | undefined {
    if (!price) {
      return
    }
    const collateralValue = this.collateralHeld.div(this.lamportsPerCollateralUnit).mul(price)
    const normalizedDebt = this.denormalizedDebtExtended
      .mul(this.cumulativeRate)
      .div(LAMPORTS_PER_SOL)
    const ratio = collateralValue.div(normalizedDebt)
    return ratio
  }

  displayCurrentCollateralRatio(price: number): string {
    if (!price) {
      return 'loading'
    }
    return this.getCurrentCollateralRatio(price).mul(100).toFixed(2) + '%'
  }

  isEmergencyMode(price: number): boolean | undefined {
    if (!price) return
    const ratio = this.getCurrentCollateralRatio(price)
    return ratio.lessThan(this.emergencyModeThreshold)
  }

  getRecoveryPrice(): Decimal {
    if (this.collateralHeld.isZero() || this.getTotalDebtExtended().isZero()) {
      return new Decimal(0)
    }

    const recoveryPrice = this.emergencyModeThreshold
      .mul(this.getTotalDebtExtended().div(LAMPORTS_PER_SOL))
      .div(this.collateralHeld.div(this.lamportsPerCollateralUnit))
    return recoveryPrice
  }

  displayRecoveryPrice(): string {
    return this.hasRecoveryMode() ? `$${numberWithCommas(this.getRecoveryPrice(), 2)} USD` : 'Never'
  }

  hasRecoveryMode(): boolean {
    return this.emergencyModeThreshold.greaterThan(this.minCollateralRatio)
  }

  displayLiquidationCollateralRatio(): string {
    return new Decimal(this.liquidationCollateralRatio.toNumber()).mul(100).toString()
  }
}
